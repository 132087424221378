<template>
  <div>
    <b-card
      id="product-form-detail-base"
      ref="product-form-detail-base"
      no-body
      class="custom-border-top-1px"
    >
      <div class="card-header">
        <b-badge
          variant="light-primary"
          class="font-small-4 px-1 py-50"
        >
          THÔNG TIN CƠ BẢN
        </b-badge>
        <b-badge
          class="font-small-4 px-1 py-50"
          :variant="value.close_deal_type === 'active' ? 'light-success' : 'light-danger'"
        >
          <feather-icon
            size="20"
            :icon="value.close_deal_type === 'active' ? 'BookOpenIcon' : 'CheckSquareIcon'"
          />
          {{ value.close_deal_type === 'active' ? 'Giao dịch đang mở' : 'Close deal' }}
        </b-badge>
      </div>
      <div class="card-body">
        <b-row>
          <b-col
            cols="12"
            lg="4"
          >
            <div class="detail-item">
              <div class="detail-label">
                Hình thức
              </div>
              <span class="detail-text">
                <b-badge variant="primary">
                  {{ (FORMALITY_PRODUCT.find(item => item.value === value.formality) || {}).text || '' }}
                </b-badge>
              </span>
            </div>

            <div class="detail-item">
              <div class="detail-label">
                Loại
              </div>
              <span class="detail-text">
                <b-badge variant="primary">
                  {{ (TYPE_PRODUCT.find(item => item.value === value.type) || {}).text || '' }}
                </b-badge>
              </span>
            </div>

            <div class="detail-item">
              <div class="detail-label">
                Tỉnh
              </div>
              <span class="detail-text">
                {{ (PROVINCE.find(item => item.id === value.province_id) || {}).text || '' }}
              </span>
            </div>

            <div class="detail-item">
              <div class="detail-label">
                Quận/Huyện
              </div>
              <span class="detail-text">
                {{ value.district && value.district.name }}
              </span>
            </div>

            <div class="detail-item">
              <div class="detail-label">
                Phường/Xã
              </div>
              <span class="detail-text">
                {{ value.ward && value.ward.name }}
              </span>
            </div>

            <div class="detail-item">
              <div class="detail-label">
                Đường
              </div>
              <span class="detail-text">
                {{ value.street && value.street.name }}
              </span>
            </div>

            <div class="detail-item">
              <div class="detail-label">
                Số nhà
              </div>
              <span class="detail-text">
                {{ value.house_number }}
              </span>
            </div>

          </b-col>
          <b-col
            cols="12"
            lg="4"
          >
            <div class="detail-item">
              <div class="detail-label">
                Ngang
              </div>
              <span class="detail-text">
                {{ value.width }} <small>m</small>
              </span>
            </div>

            <div class="detail-item">
              <div class="detail-label">
                Dài
              </div>
              <span class="detail-text">
                {{ value.length }} <small>m</small>
              </span>
            </div>

            <div
              v-if="value.type !== 'san-thuong'"
              class="detail-item"
            >
              <div class="detail-label">
                Số lầu
              </div>
              <span class="detail-text">
                {{ value.floor_number }} <small>lầu</small>
              </span>
            </div>

            <div
              v-if="value.type === 'khach-san'"
              class="detail-item"
            >
              <div class="detail-label">
                Số phòng
              </div>
              <span class="detail-text">
                {{ value.room_number_total }} <small>phòng</small>
              </span>
            </div>

            <div class="detail-item">
              <div class="detail-label">
                Diện tích
              </div>
              <span class="detail-text">
                {{ value.acreage }} <small>m<sup>2</sup></small>
              </span>
            </div>

            <div
              v-if="value.formality === 'sang-nhuong'"
              class="detail-item"
            >
              <div class="detail-label">
                Sang nhượng
              </div>
              <span class="detail-text">
                {{ formatNumber(value.transfer_price) }} <small>{{ value.transfer_price_currency }}</small>
              </span>
            </div>

            <div class="detail-item">
              <div class="detail-label">
                Thanh toán
              </div>
              <span class="detail-text">
                {{ (TYPE_OF_PAYMENT.find(item => item.value === value.type_of_payment) || {}).text || '' }}
              </span>
            </div>

            <!--            <div-->
            <!--              v-if="value.type === 'khach-san'"-->
            <!--              class="detail-item"-->
            <!--            >-->
            <!--              <div class="detail-label">-->
            <!--                Số sao-->
            <!--              </div>-->
            <!--              <span class="detail-text">-->
            <!--                <b-form-rating-->
            <!--                  id="'b-form-group-rating-' + id"-->
            <!--                  v-model="value.rating_stars"-->
            <!--                  stars="7"-->
            <!--                  readonly-->
            <!--                  variant="primary"-->
            <!--                />-->
            <!--              </span>-->
            <!--            </div>-->

            <div class="detail-item">
              <div class="detail-label">
                Giá tổng
              </div>
              <span class="detail-text">
                {{ formatNumber(value.input_price) }}
                <small>{{ ((CURRENCY.find(item => item.value === (value.currency || 'million')) || {}).text || '').toUpperCase() }}</small>
                <template v-if="value.type_of_payment === 'month'"> / tháng</template>
              </span>
            </div>

          </b-col>

          <b-col
            cols="12"
            lg="4"
          >
            <div
              v-if="value.host_name"
              class="detail-item"
            >
              <div class="detail-label">
                Chủ nhà
              </div>
              <span class="detail-text">
                {{ value.host_name }}
              </span>
            </div>

            <div class="detail-item">
              <div class="detail-label">
                SĐT
              </div>
              <span class="detail-text">
                <template v-if="value.host_phone1 || value.host_phone2">
                  <template v-if="value.host_phone1">
                    {{ value.host_phone1 }}
                  </template>
                  <template v-if="value.host_phone1 && value.host_phone2">
                    -
                  </template>
                  <template v-if="value.host_phone2">
                    {{ value.host_phone2 }}
                  </template>
                  <br>
                </template>
                <div :class="value.is_hidden_phone ? 'text-warning' : 'text-primary'">{{ value.is_hidden_phone ? '(Ẩn số chủ nhà)' : '' }}</div>
              </span>
            </div>

            <div class="detail-item">
              <div class="detail-label">
                Nguồn
              </div>
              <div class="detail-text d-flex">
                <span class="avatar mr-50">
                  <b-avatar
                    size="40"
                    class="avatar-border-2 box-shadow-1 badge-minimal"
                    :src="getUrlFile(value.source && value.source.avatar)"
                    alt="Ảnh đại diện"
                  >
                    <feather-icon
                      v-if="value.source && !value.source.avatar"
                      icon="UserIcon"
                      size="22"
                    />
                  </b-avatar>
                </span>
                <span class="d-flex flex-column">
                  <small class="font-weight-bolder">{{ (value.source && value.source.fullname) || 'Họ và tên' }}</small>
                  <span>{{ (value.source && value.source.phone) || '' }}</span>
                </span>
              </div>
            </div>

            <div class="detail-item">
              <div class="detail-label">
                Người hỗ trợ
              </div>
              <div
                v-if="value.contact"
                class="detail-text d-flex"
              >
                <span class="avatar mr-50">
                  <b-avatar
                    size="40"
                    class="avatar-border-2 box-shadow-1 badge-minimal"
                    :src="getUrlFile(value.contact && value.contact.avatar)"
                    alt="Ảnh đại diện"
                  >
                    <feather-icon
                      v-if="value.contact && !value.contact.avatar"
                      icon="UserIcon"
                      size="22"
                    />
                  </b-avatar>
                </span>
                <span
                  class="d-flex flex-column"
                >
                  <small class="font-weight-bolder">{{ (value.contact && value.contact.fullname) || 'Họ và tên' }}</small>
                  <span>{{ (value.contact && value.contact.phone) || '' }}</span>
                </span>
              </div>
              <span
                v-else
                class="flex-grow-1 text-secondary"
              >(Không có)</span>
            </div>

            <div class="detail-item">
              <div class="detail-label">
                Kiểu nhà
              </div>
              <span class="detail-text">
                <b-form-checkbox
                  v-for="item in HOUSE_STYLE"
                  :key="'product-form-detail-housestyle-' + item.value"
                  v-model="value[item.value]"
                  :disabled="true"
                >{{ item.text }}</b-form-checkbox>
              </span>
            </div>

            <div class="detail-item">
              <div class="detail-label">
                Mở rộng
              </div>
              <span class="detail-text">
                {{ (EXPAND_STYLE.find(item => item.value === value.expand_style) || {}).text || '' }}
                <template v-if="value.expand_style_info">({{ value.expand_style_info }} m)</template>
              </span>
            </div>

            <div class="detail-item">
              <div class="detail-label">
                Đặc biệt
              </div>
              <span class="detail-text">
                <b-form-checkbox
                  v-for="item in SPECIAL"
                  :key="'product-form-detail-housestyle-' + item.value"
                  v-model="value[item.value]"
                  :disabled="true"
                >{{ item.text }}</b-form-checkbox>
              </span>
            </div>

            <div class="detail-item">
              <div class="detail-label">
                Hoa hồng
              </div>
              <span class="detail-text">
                {{ (COMMISSION.find(item => item.value === value.commission) || {}).text || '' }}
                <template v-if="value.input_tl">
                  ({{ value.input_tl }})
                </template>
              </span>
            </div>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BBadge, BCard, BCol, BFormCheckbox, BRow, VBTooltip, BAvatar,
} from 'bootstrap-vue'
import ability from '@/libs/acl/ability'
import { subject } from '@casl/ability'

export default {
  components: {
    BBadge,
    BCard,
    BCol,
    BRow,
    BFormCheckbox,
    BAvatar,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    value: {
      type: Object,
      require: true,
      default: () => {},
    },
  },
  data() {
    return {
      ability,
      subject,
    }
  },
  methods: {
  },
  setup() {
  },
}
</script>

<style lang="scss">
@import 'src/@core/scss/vue/libs/quill.scss';

</style>
